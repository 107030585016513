.prop-highlight{
  .card-body-adition{
    background: @brand-color2;
  }
}

.property-card {
  border:none;
  transition: all .4s ease 0s;
  margin-bottom:20px;
  background: none;

  &:hover{
    .card-body{
      margin-top:-10px;
      opacity:.9;
    }
  }

  a{
    text-decoration: none;
  }

  a.image-link{
    position: relative;
  }

  .image-overlay{
    transition: all .6s ease 0s;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    #gradient.vertical(fade(@brand-color,10%), fade(@brand-color,100%),50%);
    opacity:50%;
  }

  .propertygrid-meta{
    margin-right:15px;
    position:relative;
    padding-right:15px;
    color: @body-color-darker;
    font-weight:bold;
    font-size: 15px;

    &:before{
      position: absolute;
      right: 0;
      background: rgba(0,0,0,.1);
      height: 20px;
      width: 1px;
      content: '';
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child{
      padding-right:0;
      margin-right:0;

      &:before{
        display:none;
      }
    }

  }



  .card-body {
    padding:1.5rem;
    box-shadow: 0 10px 20px rgba(0,0,0, 5%);
    transition: all .4s ease 0s;

    .price {
      font-size:28px;
      font-weight: 500;
      color: @property-card-body-price-color;
      span{
        font-size:50%;
      }
    }

    .location{
      margin-bottom:5px;
      font-size:13px;
      margin-top:10px;
      color: @body-color-darker;
      //font-weight: bold;
    }
    svg{
      fill:@property-card-body-svg-fill;
    }
  }

  .card-img, .card-img-top {
    border-radius: @property-card-border-radius;
  }
  .propertygrid-meta-wrapper{
    border: 2px solid #F3F7F9;
    border-radius:5px;
    padding:10px;
    margin-top:10px;
  }

  .property-title{
    margin-top:13px;
    display:block;
    color: @property-card-body-title-color;
    font-size: @property-card-body-title-size;
    height:50px;
    overflow: hidden;
    font-weight:600;
    line-height: 120%;
  }
}


.card-body{
  background: @property-card-body-bg;
  border-radius: @property-card-border-radius;
  margin:-60px 10px 15px 10px;
  position:relative;
}


.container-with-map{
  padding: 0;
  display: inline-flex;
  height: ~"calc( 100vh - 100px)";
  width: 100%;
}
.map-container{
  width: ~"calc(100% - 750px)";
  height: 100%;
  position: relative;
}

.results-container{
  width:750px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding:10px;
}

.with-map #footer{
  display:none;
}

.leaflet-control-attribution{
  display:none;
}