
.site-main-nav.navbar-collapse.show, .site-main-nav.navbar-collapse.collapsing{
  background: @site-nav-collapse-bg;
}

//.bootstrap-select {
.dropdown-toggle::after {
  //background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8'   xmlns='http://www.w3.org/2000/svg'><path d='M10.667 1.667L6 6.333 1.333 1.667' stroke='#fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTInIGhlaWdodD0nOCcgICB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00xMC42NjcgMS42NjdMNiA2LjMzMyAxLjMzMyAxLjY2NycgZmlsbD0ibm9uZSIgc3Ryb2tlPScjZmZmJyBzdHJva2Utd2lkdGg9JzEuNScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbGluZWpvaW49J3JvdW5kJz48L3BhdGg+PC9zdmc+");
  border: none;
  width: 14px;
  height: 8px;
  fill: #fff;
  stroke: #fff;
  margin: 0 0 0 8px;

  background-repeat: no-repeat;
  transition: all .4s ease 0s;
}

.dropdown-toggle.show::after {
  transform: rotate(180deg);
}



//}
.pagination{
  --bs-pagination-color:@brand-color;
  --bs-pagination-active-bg:@brand-color;
  --bs-pagination-active-border-color:@brand-color;
  .page-item{
    margin:0 5px;
    .page-link {
      border-radius: 999px;
    }
  }
}

#header-main-menu-collapse{
  //background: @brand-color;
}


.site-main-nav{
  margin-top:38px;
//  margin-left:30px;
  border-bottom:3px solid @brand-color2;

  .dropdown-item.active, .dropdown-item:active{
    background: @site-nav-submenu-active-bg;
  }

  .dropdown-menu{
    background: @site-nav-submenu-bg;

    .dropdown-item {
      color:@site-nav-submenu-color;

      &:hover{
        color: @site-nav-submenu-hover-color;
        background: @site-nav-submenu-hover-bg;
      }
    }
  }
}

.navbar {
  --bs-navbar-nav-link-padding-x: 15px;
  --bs-navbar-color:@site-nav-color;
  --bs-navbar-active-color: @site-nav-active-color;
}

.nav-pills {
  --bs-nav-pills-link-active-bg: @site-nav-active-bg;
}

.nav-link{
  --bs-nav-link-font-size: @site-nav-fontsize;
  --bs-nav-link-font-weight: @site-nav-fontweight;
}

@media(max-width: @screen-md)
{
  .site-main-nav{
    margin-top:0;
    padding:20px;
  }

}