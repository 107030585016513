a:focus{
  outline:none;
}
.svg-defs{
  display:none;
}


.background-cover {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.background-cover-center{
  background-position: center center;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-responsive{
  display: block;
  max-width: 100%;
  height: auto;
}

.center-title{
  h1,h2,h3{
    text-align: center;
  }
}

.wow{
  visibility: hidden;
}

.linewrap{
  button {
    white-space: normal !important;
  }
}

.form-group{
  margin-bottom:10px;
}

.text-short-wrapper{
  .text-short-show-more{
    display:inline-block;
  }
  .text-short{
    overflow: hidden;
    max-height:110px;

    position: relative;
    &::after{
      content:'';
      position: absolute;
      bottom:0;
      left:0;
      right:0;
      height:15px;
      box-shadow: inset 0px -20px 17px -10px #FFFFFF;
    }
  }

}

.loader-wrapper{
  position: absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background: rgba(0,0,0,.3);
}