/*
* Common
*/
.btn-primary {
  --bs-btn-bg: #E3B071;
  --bs-btn-border-color: #E3B071;
  --bs-btn-hover-border-color: darkern(#E3B071, 5%);
  --bs-btn-hover-bg: #db9846;
  --bs-btn-active-bg: #db9846;
}
.btn-primary svg {
  fill: #fff;
}
.btn-filtering {
  --bs-btn-bg: #13C0AF;
  --bs-btn-border-color: #13C0AF;
  --bs-btn-hover-border-color: darkern(#13C0AF, 5%);
  --bs-btn-hover-bg: #0e9285;
  --bs-btn-active-bg: #0e9285;
}
.search-form .btn-check:active + .btn,
.search-form .btn-check:checked + .btn,
.search-form .btn.active,
.search-form .btn.show,
.search-form .btn:active {
  background: #2F2F40;
  color: #fff;
}
.text-short-show-more {
  display: none;
  color: #fff;
  background: #13C0AF;
  padding: 5px 10px;
  font-size: 12px;
  text-decoration: none;
  border-radius: 5px;
  margin: 10px auto;
}
.homepage-splash .half-splash {
  min-height: 600px;
  padding: 250px 0;
}
.half-splash {
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 40px 0;
}
.half-splash .half-splash-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
}
.half-splash .half-splash-overlay {
  opacity: .8;
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.half-splash .half-splash-overlay.half-splash-mode-right {
  left: 50%;
}
.half-splash .half-splash-overlay.half-splash-mode-full {
  width: 100%;
}
.half-splash .half-splash-overlay.half-splash-color-white {
  background: #fff;
  opacity: .6;
}
.half-splash .half-splash-overlay.half-splash-color-blue {
  background: #1479B4;
}
.half-splash .half-splash-overlay.half-splash-color-green {
  background: #49cf47;
}
.half-splash .half-splash-overlay.half-splash-color-orange {
  background: #FF8000;
}
.half-splash .half-splash-overlay.half-splash-color-black {
  background: #000;
  opacity: .2;
}
.half-splash .half-splash-overlay.half-splash-color-grey {
  background: #757584;
  opacity: .6;
}
.half-splash .slider-gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40%;
}
.half-splash .half-splash-content {
  display: block;
  position: relative;
}
.half-splash .half-splash-content p,
.half-splash .half-splash-content h1,
.half-splash .half-splash-content h2,
.half-splash .half-splash-content h3,
.half-splash .half-splash-content h4 {
  color: #fff;
}
.half-splash label {
  color: #fff;
}
.modal-search {
  --bs-modal-bg: #fff;
  --bs-modal-header-border-color: none;
  --bs-modal-footer-border-color: none;
}
.modal-search h3 {
  color: #E3B071;
}
.modal-search .search-form-wrapper {
  padding: 0;
}
.modal-search .search-item {
  margin-top: 25px;
}
.modal-search .form-group-picker {
  width: 100%;
}
.modal-search .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}
.modal-search .search-inner .form-group-picker .btn,
.modal-search .search-inner .form-control {
  background: rgba(0, 0, 0, 0.01);
  color: #444;
}
.modal-search .search-inner .form-control {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.modal-search .search-inner .btn-search-item {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.search-form label {
  color: #111111;
}
.search-form .search-item {
  margin-top: 10px;
}
.search-form-wrapper {
  position: relative;
}
.search-form-wrapper .icon svg {
  fill: #E3B071;
  width: 20px;
  height: 20px;
}
.shortsearchform .btn-check-transaction {
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  padding: 20px 40px;
}
.shortsearchform .btn-check-transaction {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.shortsearchform .btn-check:active + .btn,
.shortsearchform .btn-check:checked + .btn,
.shortsearchform .btn.active,
.shortsearchform .btn.show,
.shortsearchform .btn:active {
  background: #fff;
  color: #2F2F40;
}
.shortsearchform .dropdown-toggle::after {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.search-inner {
  background: linear-gradient(90deg, #FFFFFF 0.1%, rgba(255, 255, 255, 0.8) 100%);
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  border-top-left-radius: 0;
}
.search-inner .form-group-picker {
  position: relative;
}
.search-inner .form-group-picker .icon {
  position: absolute;
  top: 5px;
  left: 10px;
}
.search-inner .dropdown-menu {
  --bs-dropdown-bg: #2F2F40;
  --bs-dropdown-color: #fff;
  --bs-dropdown-link-color: #fff;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.1);
  --bs-dropdown-link-hover-color: #fff;
}
.search-inner .form-group-picker .btn {
  padding-left: 40px;
  padding-right: 10px;
  border: none;
  background: none;
  color: #2F2F40;
  outline: none !important;
  box-shadow: none;
}
.search-inner .form-group-picker .btn.show {
  background: rgba(255, 255, 255, 0.1);
}
.search-inner .form-control {
  background: none;
}
.search-inner .btn-search {
  background: #13C0AF;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 10px 30px;
}
.search-inner .btn-search:hover {
  border: 1px solid #9c641f;
  color: #fff;
  background: #E3B071;
  border: none;
}
.search-inner .btn-search svg {
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-right: 5px;
}
.short-search-line {
  height: 50px;
  width: 1px;
  display: block;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}
@media (max-width: 992px) {
  .short-search-line {
    display: none;
  }
  .btn-search {
    min-width: 200px;
  }
}
@media (max-width: 480px) {
  .search-form .btn-search {
    width: 100%;
    margin-top: 20px;
  }
  .search-form .form-group-picker {
    width: 100%;
    margin-top: 20px;
  }
  .search-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }
}
.property-main-gallery,
.complex-main-gallery {
  background: #2F2F40;
}
#property-main-gallery-pager {
  display: none;
}
.property-main-gallery-item,
.complex-main-gallery-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.property-main-gallery-item img,
.complex-main-gallery-item img {
  width: auto !important;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  max-height: 600px;
}
.owl-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.owl-nav .owl-next {
  position: absolute;
  right: 10px;
}
.owl-nav .owl-prev {
  position: absolute;
  left: 10px;
}
.owl-nav .owl-next,
.owl-nav .owl-prev {
  border-radius: 999px !important;
  border: 2px solid #fff !important;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 3px;
}
.owl-nav .owl-next:hover,
.owl-nav .owl-prev:hover {
  background: rgba(47, 47, 64, 0.5) !important;
}
.owl-nav .owl-next span,
.owl-nav .owl-prev span {
  font-size: 40px;
  line-height: 30px;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .property-main-gallery-item img,
  .complex-main-gallery-item img {
    max-height: 350px;
  }
}
.contact-box-icon {
  display: inline-block;
  width: 20px;
}
.header-band {
  padding: 150px 0 30px 0;
  margin: 0;
  background: #2F2F40;
}
.header-band.header-with-image {
  position: relative;
  display: block;
}
.header-band.header-with-image h1,
.header-band.header-with-image h2,
.header-band.header-with-image h3 {
  color: #fff;
}
.header-band.header-with-image .container {
  position: relative;
}
.header-band.header-with-image .header-band-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: .7;
  background-size: cover;
}
/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small div {
  background-color: #6ecc39;
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: #f0c20c;
}
.leaflet-oldie .marker-cluster-large div {
  background-color: #f18017;
}
.marker-cluster {
  background-clip: padding-box;
  border-radius: 999px;
}
.marker-cluster div {
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 999px;
  font-size: 15px;
  background-color: rgba(241, 128, 23, 0.9);
  color: #fff;
}
.marker-cluster span {
  line-height: 50px;
}
.osm-marker-icon {
  border-radius: 999px;
  height: 30px !important;
  width: 30px !important;
  background-color: #EE284B;
}
.swiper {
  background: #2F2F40;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  background: #2F2F40;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  opacity: .2;
}
.swiper-slide.swiper-slide-active img {
  opacity: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text-icon {
  background: #fff;
  border-radius: 0.375rem;
  text-align: center;
  padding: 1.875rem;
  box-shadow: 0 3px 20px 0 rgba(8, 15, 52, 0.06);
  margin: 1.875rem auto;
  color: #000;
  flex-grow: 1;
}
.text-icon h1,
.text-icon h2,
.text-icon h3,
.text-icon h4 {
  color: #000 !important;
}
.text-icon a {
  font-size: 1rem;
  font-weight: bold;
  color: #13C0AF;
  text-decoration: none;
}
.text-icon .text-icon-icon {
  background: #f5f8fa;
  display: inline-flex;
  width: 100px;
  height: 100px;
  border-radius: 9999px;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
}
.text-icon .text-icon-body {
  min-height: 120px;
}
.text-icon svg {
  width: 40px;
  height: 40px;
  align-self: center;
}
.social-media-wrapper {
  display: flex;
  gap: 5px;
  padding-bottom: 20px;
}
.social-media-wrapper .contact-social-item svg {
  width: 25px;
  height: 25px;
}
.properties-slideshow-item {
  height: 550px;
  position: relative;
}
.properties-slideshow-item:hover .properties-slideshow-item-body {
  bottom: 0.5rem;
  opacity: .9;
}
.properties-slideshow-item-body {
  padding: 1.5rem;
  transition: all .4s ease 0s;
  width: 350px;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  background: rgba(227, 176, 113, 0.9);
  border-radius: 15px;
}
.properties-slideshow-item-body .price {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
}
.properties-slideshow-item-body .price span {
  font-size: 50%;
}
.properties-slideshow-item-body .location {
  margin-bottom: 5px;
  font-size: 13px;
  margin-top: 10px;
  color: #fff;
}
.properties-slideshow-item-body svg {
  fill: #fff;
}
.properties-slideshow-item-body .propertygrid-meta-wrapper {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}
.properties-slideshow-item-body .property-title {
  margin-top: 13px;
  display: block;
  color: #fff;
  font-size: 14px;
  height: 50px;
  overflow: hidden;
  font-weight: 600;
  line-height: 120%;
}
.properties-slideshow-item-body .propertygrid-meta {
  margin-right: 15px;
  position: relative;
  padding-right: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.properties-slideshow-item-body .propertygrid-meta:before {
  position: absolute;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  height: 20px;
  width: 1px;
  content: '';
  top: 50%;
  transform: translateY(-50%);
}
.properties-slideshow-item-body .propertygrid-meta:last-child {
  padding-right: 0;
  margin-right: 0;
}
.properties-slideshow-item-body .propertygrid-meta:last-child:before {
  display: none;
}
.properties-slideshow .owl-stage .owl-item:nth-child(odd) > div {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.properties-slideshow .owl-stage .owl-item:nth-child(even) > div {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.testimonial-slider-item {
  max-width: 900px;
  margin: 0 auto;
  background: #F3F7F9;
  text-align: center;
  padding: 50px 30px 0 30px;
  border-radius: 15px;
  position: relative;
}
.testimonial-slider-item-content {
  font-style: italic;
}
.testimonial-slider-item-author {
  margin: 20px auto -30px;
  text-align: center;
  display: inline-block;
  background: #fff;
  border-radius: 9999px;
  padding: 15px 25px;
  font-weight: 500;
}
.testimonial-slider-item-author img {
  display: inline-block !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 9999px;
  margin: -15px 15px -15px -25px;
}
.testimonial-slider-item-quote1 {
  position: absolute;
  top: 10px;
  left: 10px;
}
.testimonial-slider-item-quote1 svg {
  fill: #13C0AF;
  width: 60px;
  height: 60px;
}
.testimonial-slider-item-quote2 {
  position: absolute;
  bottom: 10px;
  right: 10px;
  transform: rotate(180deg);
}
.testimonial-slider-item-quote2 svg {
  fill: #13C0AF;
  width: 60px;
  height: 60px;
}
body {
  -webkit-font-smoothing: antialiased;
  font-size: 0.9rem;
  font-family: "Montserrat", Aria, sans-serif;
  background-color: #fff;
  line-height: 160%;
  min-height: 100%;
  color: #444;
}
a:hover {
  color: #E3B071;
}
h1,
h2,
h3 {
  font-family: "Montserrat", Aria, sans-serif;
  color: #303030;
}
h1 {
  margin: 30px 0;
}
h2 {
  margin: 20px 0;
}
h1.container-title,
h2.container-title {
  position: relative;
  padding-left: 25px;
}
h1.container-title span,
h2.container-title span {
  color: #E3B071;
}
h1.container-title:after,
h2.container-title:after {
  content: " ";
  display: block;
  width: 7px;
  background: #E3B071;
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 5px;
}
.center-title h1:after,
.center-title h2:after {
  content: " ";
  display: none;
}
.border-8 {
  padding: 8px !important;
}
a:focus {
  outline: none;
}
.svg-defs {
  display: none;
}
.background-cover {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.background-cover-center {
  background-position: center center;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.center-title h1,
.center-title h2,
.center-title h3 {
  text-align: center;
}
.wow {
  visibility: hidden;
}
.linewrap button {
  white-space: normal !important;
}
.form-group {
  margin-bottom: 10px;
}
.text-short-wrapper .text-short-show-more {
  display: inline-block;
}
.text-short-wrapper .text-short {
  overflow: hidden;
  max-height: 110px;
  position: relative;
}
.text-short-wrapper .text-short::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15px;
  box-shadow: inset 0px -20px 17px -10px #FFFFFF;
}
.loader-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}
.label-wrapper {
  position: absolute;
  left: 10px;
  top: 10px;
  display: block;
  z-index: 1;
}
.label-wrapper .label {
  padding: 3px 10px;
  font-size: 11px;
  color: #fff;
  background: #E3B071;
  border-radius: 20px;
  font-weight: bold;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 500;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 500;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 500;
}
.form-label,
label {
  font-weight: 700;
}
.form-group {
  margin-bottom: 15px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  height: 48px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #fbfbfb;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  font-weight: 500;
}
.input-group-text {
  background-color: #f6f6f6;
  border: 2px solid transparent;
}
.has-error input {
  border: solid 2px red !important;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus,
.uneditable-input:focus {
  border-color: #13C0AF;
  box-shadow: none;
  outline: 0 none;
}
textarea.form-control {
  height: auto;
}
.btn {
  padding: 10px 24px;
  border-style: solid;
  border-radius: 4px;
  -webkit-transition: border-color 200ms ease, color 200ms ease, background-color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease, background-color 200ms ease;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6px;
  text-decoration: none;
}
.btn-form {
  background-color: #2F2F40;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
}
.btn-form-right {
  margin-left: auto !important;
}
.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
header {
  background: none;
  z-index: 20;
}
.logo {
  max-height: 170px;
}
@media (max-width: 992px) {
  header {
    height: 80px;
  }
  .logo {
    max-height: 80px;
  }
}
footer {
  background: #2F2F40;
  color: rgba(255, 255, 255, 0.9);
  fill: rgba(255, 255, 255, 0.9);
  padding-top: 50px;
  padding-bottom: 30px;
}
footer h1,
footer h2,
footer h3,
footer h4 {
  color: #E3B071;
}
footer a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
footer ul {
  padding-left: 0;
}
footer ul li {
  list-style: none;
}
.footer-partner svg {
  width: 25px;
  height: 25px;
}
.footer-bottom {
  margin-top: 20px;
  font-size: 13px;
}
.footer-bottom::after {
  content: "";
  clear: both;
  display: table;
}
.divider-simple {
  padding-top: 50px;
  padding-bottom: 50px;
}
.divider-grey {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #2F2F40;
  color: #fff;
}
.divider-grey h1,
.divider-grey h2,
.divider-grey h3 {
  color: #fff;
}
.divider-center .container-title {
  text-align: center;
}
.divider-center .container-title:after {
  display: none;
}
.divider-lightgrey {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #F3F7F9;
}
.divider-fullscreen-margin {
  margin: 0 15px;
}
.divider-fullscreen-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.divider-fullscreen-title-margin h1,
.divider-fullscreen-title-margin h2 {
  margin-left: 15px;
}
.dark-container {
  background: #2F2F40;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
}
.light-container {
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
}
.site-main-nav.navbar-collapse.show,
.site-main-nav.navbar-collapse.collapsing {
  background: #2F2F40;
}
.dropdown-toggle::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTInIGhlaWdodD0nOCcgICB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00xMC42NjcgMS42NjdMNiA2LjMzMyAxLjMzMyAxLjY2NycgZmlsbD0ibm9uZSIgc3Ryb2tlPScjZmZmJyBzdHJva2Utd2lkdGg9JzEuNScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbGluZWpvaW49J3JvdW5kJz48L3BhdGg+PC9zdmc+");
  border: none;
  width: 14px;
  height: 8px;
  fill: #fff;
  stroke: #fff;
  margin: 0 0 0 8px;
  background-repeat: no-repeat;
  transition: all .4s ease 0s;
}
.dropdown-toggle.show::after {
  transform: rotate(180deg);
}
.pagination {
  --bs-pagination-color: #2F2F40;
  --bs-pagination-active-bg: #2F2F40;
  --bs-pagination-active-border-color: #2F2F40;
}
.pagination .page-item {
  margin: 0 5px;
}
.pagination .page-item .page-link {
  border-radius: 999px;
}
.site-main-nav {
  margin-top: 38px;
  border-bottom: 3px solid #E3B071;
}
.site-main-nav .dropdown-item.active,
.site-main-nav .dropdown-item:active {
  background: #E3B071;
}
.site-main-nav .dropdown-menu {
  background: #2F2F40;
}
.site-main-nav .dropdown-menu .dropdown-item {
  color: #fff;
}
.site-main-nav .dropdown-menu .dropdown-item:hover {
  color: rgba(255, 255, 255, 0.7);
  background: none;
}
.navbar {
  --bs-navbar-nav-link-padding-x: 15px;
  --bs-navbar-color: #fff;
  --bs-navbar-active-color: #fff;
}
.nav-pills {
  --bs-nav-pills-link-active-bg: rgba(255, 255, 255, 0.1);
}
.nav-link {
  --bs-nav-link-font-size: 16px;
  --bs-nav-link-font-weight: 600;
}
@media (max-width: 992px) {
  .site-main-nav {
    margin-top: 0;
    padding: 20px;
  }
}
.home-about {
  background: #F3F7F9;
  padding: 1.5rem;
  margin-top: -50px;
  margin-bottom: -50px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-left: 70px;
}
.home-about p {
  max-width: 800px;
}
@media (max-width: 992px) {
  .home-about {
    padding-left: 20px;
  }
}
.agent-info-box {
  position: relative;
}
.agent-info-box .agent-info-box-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: -webkit-linear-gradient(top, rgba(47, 47, 64, 0) 0%, rgba(47, 47, 64, 0.8) 100%);
  background: -o-linear-gradient(top, rgba(47, 47, 64, 0) 0%, rgba(47, 47, 64, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(47, 47, 64, 0) 0%, rgba(47, 47, 64, 0.8) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002f2f40', endColorstr='#cc2f2f40', GradientType=0);
}
.agent-info-box .agent-info-box-content .agent-info-box-content-inner {
  position: absolute;
  bottom: 20px;
  padding: 0 15px;
}
.agent-info-box .agent-info-box-content .agent-info-box-content-inner .agent-info-box-title {
  font-size: 18px;
  font-weight: 600;
}
.agent-info-box .agent-info-box-content .agent-info-box-content-inner ul {
  display: none;
}
.agent-show-box {
  background: #2F2F40;
  padding: 1rem;
  margin-top: 4rem;
  border-radius: 0.375rem;
}
.agent-show-box-subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.agent-info-box {
  box-shadow: 0 5px 20px 0 rgba(40, 46, 56, 0.1);
  overflow: hidden;
  border-radius: 0.375rem;
}
.agent-info-box-phone {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
  color: #E3B071;
  text-decoration: none;
  display: inline-block;
}
.agent-show-box-content {
  margin-left: 40px;
  color: #fff;
}
.agent-show-box-content .agent-show-contact-box {
  display: -webkit-box;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 600;
}
.agent-show-box-content h1,
.agent-show-box-content h2,
.agent-show-box-content h3,
.agent-show-box-content h4,
.agent-show-box-content h5,
.agent-show-box-content p {
  color: #fff;
}
.agent-show-box-content a {
  color: #E3B071;
  text-decoration: none;
}
.agent-show-box-content .agent-show-box-description {
  margin-top: 3rem;
}
.agent-show-box-image {
  box-shadow: 0 5px 20px 0 rgba(40, 46, 56, 0.1);
  position: relative;
}
.agent-show-box-image img {
  border-radius: 10px;
}
.agent-show-box-image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 46, 56, 0)), color-stop(51%, rgba(40, 46, 56, 0)), color-stop(72%, rgba(40, 46, 56, 0.5)));
  background-image: linear-gradient(180deg, rgba(40, 46, 56, 0), rgba(40, 46, 56, 0) 51%, rgba(40, 46, 56, 0.5) 72%);
}
@media (max-width: 992px) {
  .agent-show-box-content {
    margin-left: 0;
  }
}
.sticky-top-supershort-agent {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 1020;
}
.agent-info-box-super-super-short {
  display: flex;
}
.agent-info-box-super-super-short .agent-info-box-super-short-image {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 999px;
  overflow: hidden;
}
.agent-info-box-super-super-short .agent-info-box-super-short-content {
  display: flex;
  align-items: center;
}
.agent-info-box-super-super-short .agent-info-box-super-short-content-inner {
  display: flex;
}
.agent-info-box-super-short-title {
  margin-left: 20px;
  font-size: 0.9rem;
  font-weight: bold;
}
.agent-info-super-short-phone {
  font-size: 1.2rem;
  font-weight: bold;
  color: #E3B071;
  text-decoration: none;
  margin-left: 20px;
}
.agent-info-box-short {
  position: relative;
  height: 390px;
  box-shadow: 0 5px 20px 0 rgba(40, 46, 56, 0.1);
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
  max-width: 260px;
  margin-bottom: 20px;
}
.agent-info-box-short .agent-info-box-short-image img {
  max-height: 400px;
}
.agent-info-box-short .agent-info-box-short-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 46, 56, 0)), color-stop(51%, rgba(40, 46, 56, 0)), color-stop(72%, rgba(40, 46, 56, 0.5)));
  background-image: linear-gradient(180deg, rgba(40, 46, 56, 0), rgba(40, 46, 56, 0) 51%, rgba(40, 46, 56, 0.5) 72%);
}
.agent-info-box-short .agent-info-box-short-content-inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  padding-right: 24px;
  padding-left: 24px;
  color: #fff;
}
.agent-info-box-short .agent-info-box-short-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.agent-info-box-short .agent-info-phone {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
  color: #E3B071;
  text-decoration: none;
  display: inline-block;
}
@media (max-width: 768px) {
  .agentinfo-col {
    justify-content: center;
  }
  .agent-info-box-short {
    height: 250px;
    max-width: 250px;
    justify-content: center;
    margin: 0 auto 20px;
  }
  .property-show-contact-short-title {
    font-size: 1.2rem !important;
  }
  .contact-form .btn {
    width: 100%;
  }
}
.prop-highlight .card-body-adition {
  background: #E3B071;
}
.property-card {
  border: none;
  transition: all .4s ease 0s;
  margin-bottom: 20px;
  background: none;
}
.property-card:hover .card-body {
  margin-top: -10px;
  opacity: .9;
}
.property-card a {
  text-decoration: none;
}
.property-card a.image-link {
  position: relative;
}
.property-card .image-overlay {
  transition: all .6s ease 0s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-linear-gradient(top, rgba(47, 47, 64, 0.1) 50%, #2f2f40 100%);
  background: -o-linear-gradient(top, rgba(47, 47, 64, 0.1) 50%, #2f2f40 100%);
  background: linear-gradient(to bottom, rgba(47, 47, 64, 0.1) 50%, #2f2f40 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a2f2f40', endColorstr='#ff2f2f40', GradientType=0);
  opacity: 50%;
}
.property-card .propertygrid-meta {
  margin-right: 15px;
  position: relative;
  padding-right: 15px;
  color: #111111;
  font-weight: bold;
  font-size: 15px;
}
.property-card .propertygrid-meta:before {
  position: absolute;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  height: 20px;
  width: 1px;
  content: '';
  top: 50%;
  transform: translateY(-50%);
}
.property-card .propertygrid-meta:last-child {
  padding-right: 0;
  margin-right: 0;
}
.property-card .propertygrid-meta:last-child:before {
  display: none;
}
.property-card .card-body {
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: all .4s ease 0s;
}
.property-card .card-body .price {
  font-size: 28px;
  font-weight: 500;
  color: #13C0AF;
}
.property-card .card-body .price span {
  font-size: 50%;
}
.property-card .card-body .location {
  margin-bottom: 5px;
  font-size: 13px;
  margin-top: 10px;
  color: #111111;
}
.property-card .card-body svg {
  fill: #13C0AF;
}
.property-card .card-img,
.property-card .card-img-top {
  border-radius: 15px;
}
.property-card .propertygrid-meta-wrapper {
  border: 2px solid #F3F7F9;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}
.property-card .property-title {
  margin-top: 13px;
  display: block;
  color: #111111;
  font-size: 14px;
  height: 50px;
  overflow: hidden;
  font-weight: 600;
  line-height: 120%;
}
.card-body {
  background: #fff;
  border-radius: 15px;
  margin: -60px 10px 15px 10px;
  position: relative;
}
.container-with-map {
  padding: 0;
  display: inline-flex;
  height: calc( 100vh - 100px);
  width: 100%;
}
.map-container {
  width: calc(100% - 750px);
  height: 100%;
  position: relative;
}
.results-container {
  width: 750px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
.with-map #footer {
  display: none;
}
.leaflet-control-attribution {
  display: none;
}
.properties-sort {
  display: flex;
  background: #2F2F40;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
  margin-top: -25px;
  justify-content: space-between;
  border-radius: 10px;
}
.bootstrap-select.select-ordering {
  width: auto !important;
}
.sort-form-inner {
  display: flex;
  gap: 10px;
}
.container-with-map .properties-sort {
  margin-top: 0px;
}
#property-main-gallery {
  padding-top: 150px;
}
.property-wrapper {
  background: rgba(243, 247, 249, 0.3);
}
.property-show-title {
  font-size: 1.6rem;
  margin: 15px 0;
}
.property-show-price {
  padding: 15px 30px;
  margin: 15px 0;
  color: #fff;
  display: block;
  font-size: 1.3rem;
  font-weight: bold;
  background: #E3B071;
  border-radius: 0.375rem;
}
.property-show-price span {
  font-size: 50%;
}
.property-show-zone {
  font-size: 1rem;
  font-weight: bold;
}
.property-wrapper h2 {
  font-size: 1.4rem;
}
.property-wrapper h5 {
  font-size: 1rem;
  font-weight: bold;
}
.property-show-caracteristics-group .list-group-item {
  border-left: none;
  border-right: none;
  border-top: none;
}
.property-show-caracteristics-group .list-group-item span {
  font-weight: bold;
  margin-left: 10px;
  float: right;
}
.property-box-delimiter {
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
}
.spec-item {
  line-height: 180%;
  color: #212529;
  padding-bottom: 10px;
}
.spec-item span {
  background: none;
  color: #111111;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 0.375rem;
}
.spec-item span svg {
  fill: #13C0AF;
  opacity: .8;
}
#window-map {
  height: 300px;
}
.warning-box {
  background: #E53B75;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  margin: 30px 0;
  padding: 30px 0;
  font-weight: bold;
  border-radius: 15px;
}
.warning-box span {
  display: block;
}
.warning-box .warning-line2 {
  font-size: 1.2rem;
}
.property-short-caracteristics-wrapper {
  background: #13C0AF;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
}
.property-show-short-features {
  display: flex;
}
.property-show-short-feature-block {
  padding-right: 8px;
  padding-left: 8px;
}
.property-show-short-feature-block-title {
  margin-bottom: 4px;
  color: rgba(255, 255, 255, 0.75);
}
.property-show-short-feature-block-values {
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-right: 2px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}
.property-show-short-feature-block-value {
  font-size: calc(0.8rem + 0.7vw);
  line-height: 30px;
  font-weight: 800;
}
.property-show-short-feature-block:last-of-type .property-show-short-feature-block-values {
  border-right: none;
}
.property-show-short-feature-block-icon svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  fill: #fff;
  opacity: .5;
}
.property-modal-image-wrapper {
  background: #2F2F40;
}
.property-modal-image-wrapper img {
  max-height: 400px;
}
.mobile-contact-wrapper {
  animation-delay: 0.75s;
  animation: slide 0.5s forwards;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: auto;
  will-change: transform;
  z-index: 1000;
  padding: 5px 10px 10px 10px;
  display: none;
}
.mobile-contact {
  border-radius: 999px;
  width: 100%;
  padding: 5px 10px;
  background: #E3B071;
  color: #fff;
  margin-top: 5px;
}
.mobile-contact svg {
  fill: #fff;
}
.mobile-contact-phone {
  background: #13C0AF;
}
.mobile-contact-message {
  background: #2F2F40;
}
@media (max-width: 480px) {
  .mobile-contact-wrapper {
    display: block;
  }
}
.share-wrapper {
  display: flex;
  gap: 5px;
  padding-bottom: 20px;
}
.share-wrapper .btn {
  border: none;
  background: rgba(47, 47, 64, 0.8);
}
.share-wrapper .btn svg {
  width: 25px;
  height: 25px;
  fill: #fff !important;
}
.share-wrapper .social-whatsapp svg {
  fill: #25D366;
}
.share-wrapper .social-facebook svg {
  fill: #4267B2;
}
.share-wrapper .social-twitter svg {
  fill: #1DA1F2;
}
.share-wrapper .social-linkedin svg {
  fill: #0077b5;
}
.news-box-wrapper {
  padding-top: 30px;
}
.news-box {
  transition: all .4s ease 0s;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(243, 247, 249, 0.3);
}
.news-box:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
.news-box-image {
  position: relative;
}
.news-box-item-date {
  position: absolute;
  bottom: -10px;
  left: 20px;
  display: block;
  color: #fff;
  background-color: #13C0AF;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 24px;
}
.news-box-item-date span {
  display: block;
  font-size: 16px;
}
.news-box-short-info {
  padding: 1.3rem;
}
.news-box-short-info h3 {
  font-size: 22px;
  color: #2F2F40;
}
.news-box-short-info h3 a {
  color: #2F2F40;
  text-decoration: none;
}
.article-wrapper {
  margin: 50px auto 50px;
}
.article-date {
  display: block;
  color: #fff;
  background-color: #13C0AF;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: bold;
  position: absolute;
  bottom: -20px;
  right: 15px;
}
.article-date svg {
  fill: #fff;
  width: 20px;
  opacity: .7;
  height: 20px;
  margin-right: 5px;
}
.article-content {
  margin-top: 20px;
}
.article-image {
  position: relative;
}
.module-latestnews {
  margin-top: 40px;
}
.news-box-shortlist-item {
  border-radius: 10px;
  overflow: hidden;
  background: rgba(243, 247, 249, 0.3);
  display: block;
  margin-top: 10px;
}
.news-box-shortlist-item-title {
  padding: 10px;
  font-size: 16px;
  display: block;
  color: #2F2F40;
  text-decoration: none;
}
.blog-article-image {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.property-show-contact-short {
  width: 100%;
}
.property-show-contact-short {
  max-width: 600px;
}
.property-show-contact-short-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
  margin-bottom: 1rem;
  font-family: "Montserrat", Aria, sans-serif;
  color: #fff;
}
.contact-box-item {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  font-weight: 600;
}
.contact-box-item a {
  color: #444;
  text-decoration: none;
}
.contact-box-icon {
  background-color: rgba(227, 176, 113, 0.1);
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}
.contact-box-icon svg {
  width: 1.3rem;
  height: 1.3rem;
  fill: #13C0AF;
}
.footer .contact-box-item a {
  color: #fff;
}
.complexes-wrapper {
  padding-top: 30px;
}
.complex-list-box {
  transition: all .4s ease 0s;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  background: rgba(243, 247, 249, 0.3);
  position: relative;
}
.complex-list-box .complex-list-box-image img {
  width: 250px;
}
.complex-list-box:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
.complex-list-box .property-row-content {
  padding: 15px;
}
.complex-list-box .complex-list-box-title {
  font-size: 22px;
  color: #2F2F40;
  margin-bottom: 10px;
  margin-top: 5px;
}
.complex-list-box .complex-list-box-title a {
  color: #2F2F40;
  text-decoration: none;
}
.complex-list-box .complex-list-box-location {
  font-size: 14px;
}
.complex-list-box-body {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 25px;
}
.complex-list-box-body .complex-list-box-meta {
  background: #2F2F40;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.complex-list-box-body .complex-list-box-meta svg {
  fill: #fff;
}
@media (max-width: 480px) {
  .complex-list-box .complex-list-box-image img {
    width: 100%;
  }
  .complex-list-box {
    flex-direction: column;
  }
}
#complex-main-gallery {
  padding-top: 150px;
}
.complex-wrapper h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
}
.complex-show-title {
  margin: 15px 0;
  text-align: center;
}
.complex-show-zone {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  display: block;
}
.complex-show-description {
  margin-top: 30px;
  max-width: 1000px;
  text-align: center;
}
.complex-box-delimiter {
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
}
.complex-properties {
  margin-top: 30px;
}
.complex-properties h2 {
  text-align: center;
}
.complex-short-caracteristics-wrapper {
  background: #2F2F40;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
}
.complex-show-short-features {
  display: flex;
}
.complex-show-short-feature-block {
  padding-right: 8px;
  padding-left: 8px;
}
.complex-show-short-feature-block-title {
  margin-bottom: 4px;
  color: rgba(255, 255, 255, 0.75);
}
.complex-show-short-feature-block-values {
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-right: 2px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}
.complex-show-short-feature-block-value {
  font-size: calc(0.8rem + 0.7vw);
  line-height: 30px;
  font-weight: 800;
}
.complex-show-short-feature-block:last-of-type .complex-show-short-feature-block-values {
  border-right: none;
}
.complex-show-short-feature-block-icon svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  fill: #fff;
  opacity: .5;
}
.mapping-wrap {
  position: relative;
}
.mapping-wrap .hover-path {
  opacity: 1;
  fill: rgba(99, 145, 170, 0.6);
  transition: all 0.2s ease-in-out;
}
.mapping-wrap .hover-path:hover {
  opacity: 1;
  fill: rgba(47, 51, 56, 0.6);
}
.mapping-prev {
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  padding: 5px;
  background: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  color: #fff;
  transition: all .6s ease 0s;
  opacity: .5;
}
.mapping-prev:hover {
  opacity: 1;
  width: auto;
  color: #fff;
}
.mapping-prev:hover span {
  display: inline-block;
}
.mapping-prev svg {
  fill: #fff;
  stroke: #fff;
  height: 40px;
  width: 30px;
}
.mapping-prev span {
  display: none;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
}
