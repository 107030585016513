.homepage-splash .half-splash{
  min-height: 600px;
  padding:250px 0;
}


.half-splash{
  min-height: 300px;
  // background-color: @brand-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 40px 0;

  //.container{
  //  padding:0;
  //}
  .half-splash-image{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-size: cover;
    background-position: center center;

  }
  .half-splash-overlay{

    opacity:.8;
    width:50%;
    position:absolute;
    top:0;
    bottom:0;
    left:0;



    &.half-splash-mode-right{
      left: 50%;
    }

    &.half-splash-mode-full{
      width: 100%;
    }

    &.half-splash-color-white{
      background: #fff;
      opacity:.6;
    }

    &.half-splash-color-blue{
      background: #1479B4;
    }

    &.half-splash-color-green{
      background: #49cf47;
    }

    &.half-splash-color-orange{
      background: #FF8000;
    }

    &.half-splash-color-black{
      background: #000;
      opacity:.2;
    }

    &.half-splash-color-grey{
      background: #757584;
      opacity:.6;
    }


    //opacity: .2;
  }
  .slider-gradient{
    position: absolute;
    bottom:0;
    width: 100%;
    //height: 250px;
    height: 40%;
    // #gradient > .vertical(rgba(0,0,0,0), rgba(0,0,0,.7));
  }





  .half-splash-content{
    display: block;
    position:relative;
    p, h1,h2,h3,h4{
      color: #fff;
    }
  }
}

.half-splash label{
  color: #fff;
}
