body {
  -webkit-font-smoothing: antialiased;
  // font-size: 15px;
  font-size: 0.9rem;
  font-family: @font-main;
  background-color: @body-bg-color;
  line-height: 160%;
  min-height: 100%;
  color: @body-color;
}

a:hover{
  color: @brand-color2;
}

h1,h2,h3{
  font-family: @font-title;
  //font-weight: 500;
  color: #303030;
}

h1{
  // font-size: 2.7rem;
  margin:30px 0;
}

h2{
  margin:20px 0;
}

h1.container-title,h2.container-title{
  position: relative;
  padding-left:25px;
  span{
    color:@brand-color2;
  }
  &:after{
    content:" ";
    display:block;
    width:7px;
    background:@brand-color2;
    position: absolute;
    left:0;
    top:5px;
    bottom:5px;
  }
}
.center-title h1, .center-title h2{
  &:after{
    content:" ";
    display:none;
  }
}

.border-8{
  padding:8px !important;
}